// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SLq0jOt_guyz6UBOvk1g .DNmMOSISGr2jBl97QLuv {
  display: block;
  height: auto;
  padding: 0 12px 12px;
}`, "",{"version":3,"sources":["webpack://./styles/sidebar.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EAEA,YAAA;EACA,oBAAA;AADJ","sourcesContent":[".sidebarFooter {\n  .element {\n    display: block;\n\n    height: auto;\n    padding: 0 12px 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarFooter": `SLq0jOt_guyz6UBOvk1g`,
	"element": `DNmMOSISGr2jBl97QLuv`
};
export default ___CSS_LOADER_EXPORT___;
