// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MOtv9tj4mscjgzJtxj4y.MOtv9tj4mscjgzJtxj4y {
  padding: 0 40px;
}

.aHeV6F7iOqr_HLIrMJFj {
  display: flex;
  justify-content: center;
  margin-top: -2px;
  margin-bottom: 14px;
  font-weight: 800;
  font-size: 56px;
  line-height: 150%;
}

.SkGzUrsRYvXQnNUjtDfd {
  display: block;
  margin-bottom: 16px;
  line-height: 146%;
  letter-spacing: 0.0015em;
  white-space: pre-wrap;
  text-align: center;
}

.lu9PwzHrWMaNKC45yLFd {
  display: block;
  margin-bottom: 40px;
  font-weight: 400;
  line-height: 200%;
  letter-spacing: 0.0015em;
  white-space: pre-wrap;
  text-align: center;
}

.OTAx5_547YoJhi0mPv5Q {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  column-gap: 16px;
}`, "",{"version":3,"sources":["webpack://./styles/tutorialRegularModal.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EAEA,gBAAA;EACA,mBAAA;EAEA,gBAAA;EACA,eAAA;EACA,iBAAA;AADF;;AAIA;EACE,cAAA;EAEA,mBAAA;EAEA,iBAAA;EACA,wBAAA;EACA,qBAAA;EACA,kBAAA;AAHF;;AAMA;EACE,cAAA;EAEA,mBAAA;EAEA,gBAAA;EACA,iBAAA;EACA,wBAAA;EACA,qBAAA;EACA,kBAAA;AALF;;AAQA;EACE,aAAA;EACA,uBAAA;EAEA,kBAAA;EAEA,gBAAA;AAPF","sourcesContent":[".modalContentWrapper.modalContentWrapper {\n  padding: 0 40px;\n}\n\n.iconWrapper {\n  display: flex;\n  justify-content: center;\n\n  margin-top: -2px;\n  margin-bottom: 14px;\n\n  font-weight: 800;\n  font-size: 56px;\n  line-height: 150%;\n}\n\n.title {\n  display: block;\n\n  margin-bottom: 16px;\n\n  line-height: 146%;\n  letter-spacing: 0.0015em;\n  white-space: pre-wrap;\n  text-align: center;\n}\n\n.text {\n  display: block;\n\n  margin-bottom: 40px;\n\n  font-weight: 400;\n  line-height: 200%;\n  letter-spacing: 0.0015em;\n  white-space: pre-wrap;\n  text-align: center;\n}\n\n.actionsWrapper {\n  display: flex;\n  justify-content: center;\n\n  margin-bottom: 8px;\n\n  column-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContentWrapper": `MOtv9tj4mscjgzJtxj4y`,
	"iconWrapper": `aHeV6F7iOqr_HLIrMJFj`,
	"title": `SkGzUrsRYvXQnNUjtDfd`,
	"text": `lu9PwzHrWMaNKC45yLFd`,
	"actionsWrapper": `OTAx5_547YoJhi0mPv5Q`
};
export default ___CSS_LOADER_EXPORT___;
