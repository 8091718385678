// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ORywFVxhC5Tz6alo9vR1.ORywFVxhC5Tz6alo9vR1 {
  height: 52px;
}

.EQATiZ9DeqSeJbVb2LJ9.EQATiZ9DeqSeJbVb2LJ9 {
  pointer-events: none;
}

@media screen and (min-width: 500px) {
  .ORywFVxhC5Tz6alo9vR1.ORywFVxhC5Tz6alo9vR1 {
    height: 60px;
  }
}`, "",{"version":3,"sources":["webpack://./styles/input.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE;IACE,YAAA;EACF;AACF","sourcesContent":[".input.input {\n  height: 52px;\n}\n\n.pointerEventsOff.pointerEventsOff {\n  pointer-events: none;\n}\n\n@media screen and (min-width: 500px) {\n  .input.input {\n    height: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `ORywFVxhC5Tz6alo9vR1`,
	"pointerEventsOff": `EQATiZ9DeqSeJbVb2LJ9`
};
export default ___CSS_LOADER_EXPORT___;
