// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yjN6FLSbC3vQEuNQyVqA {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.yjN6FLSbC3vQEuNQyVqA .SUo2atAs8_85eMOA0slg {
  margin-top: 30px;
  font-weight: 800;
  font-size: 24px;
}
.yjN6FLSbC3vQEuNQyVqA .r5s3QcH8nGnfqPPZF8DU {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(254px, 1fr));
  gap: 16px;
  width: 100%;
}
.yjN6FLSbC3vQEuNQyVqA .r5s3QcH8nGnfqPPZF8DU .Cq_Yby_hhpvJ6Ciq3tsv {
  height: 180px;
  min-width: 170px;
  max-width: 218px;
  margin: 10px 26px;
  border-radius: 12px !important;
}
.yjN6FLSbC3vQEuNQyVqA .r5s3QcH8nGnfqPPZF8DU .Cq_Yby_hhpvJ6Ciq3tsv ._7ZnsdnjDgnA9lvSvgs6 {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 24px;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
}
.yjN6FLSbC3vQEuNQyVqA .r5s3QcH8nGnfqPPZF8DU .Cq_Yby_hhpvJ6Ciq3tsv ._7ZnsdnjDgnA9lvSvgs6 > div,
.yjN6FLSbC3vQEuNQyVqA .r5s3QcH8nGnfqPPZF8DU .Cq_Yby_hhpvJ6Ciq3tsv ._7ZnsdnjDgnA9lvSvgs6 [data-for] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.yjN6FLSbC3vQEuNQyVqA .r5s3QcH8nGnfqPPZF8DU .Cq_Yby_hhpvJ6Ciq3tsv ._7ZnsdnjDgnA9lvSvgs6 svg {
  flex: 0 0 32px;
}
.yjN6FLSbC3vQEuNQyVqA .r5s3QcH8nGnfqPPZF8DU .Cq_Yby_hhpvJ6Ciq3tsv .l6QgpQS5GnWJSmzGMJHw {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  margin-top: 52px;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./styles/kpi.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADF;AAGE;EACE,gBAAA;EAEA,gBAAA;EACA,eAAA;AAFJ;AAKE;EACE,aAAA;EACA,2DAAA;EACA,SAAA;EAEA,WAAA;AAJJ;AAMI;EACE,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;EAEA,8BAAA;AALN;AAOM;EACE,aAAA;EACA,SAAA;EACA,uBAAA;EAEA,gBAAA;EAEA,iBAAA;EACA,eAAA;EACA,kBAAA;AAPR;AASQ;;EAEE,mBAAA;EACA,uBAAA;EAEA,gBAAA;AARV;AAWQ;EACE,cAAA;AATV;AAaM;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,YAAA;EACA,gBAAA;EAEA,gBAAA;EACA,eAAA;EACA,kBAAA;AAbR","sourcesContent":["@import \"variable\";\n\n.kpiCardWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n\n  .kpiSubTitle {\n    margin-top: 30px;\n\n    font-weight: 800;\n    font-size: 24px;\n  }\n\n  .kpiCardContainer {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(254px, 1fr));\n    gap: 16px;\n\n    width: 100%;\n\n    .kpiCard {\n      height: 180px;\n      min-width: 170px;\n      max-width: 218px;\n      margin: 10px 26px;\n\n      border-radius: 12px !important;\n\n      .kpiFirstLine {\n        display: flex;\n        gap: 10px;\n        justify-content: center;\n\n        margin-top: 24px;\n\n        font-weight: bold;\n        font-size: 28px;\n        text-align: center;\n\n        > div,\n        [data-for] {\n          white-space: nowrap;\n          text-overflow: ellipsis;\n\n          overflow: hidden;\n        }\n\n        svg {\n          flex: 0 0 32px;\n        }\n      }\n\n      .kpiSecondLine {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n\n        height: 15px;\n        margin-top: 52px;\n\n        font-weight: 500;\n        font-size: 20px;\n        text-align: center;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"kpiCardWrapper": `yjN6FLSbC3vQEuNQyVqA`,
	"kpiSubTitle": `SUo2atAs8_85eMOA0slg`,
	"kpiCardContainer": `r5s3QcH8nGnfqPPZF8DU`,
	"kpiCard": `Cq_Yby_hhpvJ6Ciq3tsv`,
	"kpiFirstLine": `_7ZnsdnjDgnA9lvSvgs6`,
	"kpiSecondLine": `l6QgpQS5GnWJSmzGMJHw`
};
export default ___CSS_LOADER_EXPORT___;
