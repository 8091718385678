// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v7d8GbxCuQ00MbEZDGxQ {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v7d8GbxCuQ00MbEZDGxQ .kjTiRZgqLblM1Lxv1yE0 {
  margin-bottom: 16px;
}
.v7d8GbxCuQ00MbEZDGxQ .tQQnuWF0r0gEHrWdBggy {
  margin-top: 16px;
  margin-bottom: 12px;
}
.v7d8GbxCuQ00MbEZDGxQ .PpP7SvUBXYAbqUAP9Lge {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./styles/serviceModalHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,gBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,kBAAA;AADJ","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  .logo {\n    margin-bottom: 16px;\n  }\n\n  .title {\n    margin-top: 16px;\n    margin-bottom: 12px;\n  }\n\n  .subTitle {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `v7d8GbxCuQ00MbEZDGxQ`,
	"logo": `kjTiRZgqLblM1Lxv1yE0`,
	"title": `tQQnuWF0r0gEHrWdBggy`,
	"subTitle": `PpP7SvUBXYAbqUAP9Lge`
};
export default ___CSS_LOADER_EXPORT___;
